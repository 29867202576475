import React from "react"
import "../../../assets/scss/components/app-loader.scss"
import {Spinner} from "reactstrap";

class SpinnerComponent extends React.Component {
    render() {
        return (
            <div className="container cont">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="example">
                            <Spinner color="info"/>
                        </div>
                    </div>
                </div>
            </div>
            // <div className="fallback-spinner vh-100">
            //     <img className="fallback-logo" src={logo} alt="logo" width={160} height={50}/>
            //     <div className="loading">
            //         <div className="effect-1 effects"></div>
            //         <div className="effect-2 effects"></div>
            //         <div className="effect-3 effects"></div>
            //     </div>
            // </div>
        )
    }
}

export default SpinnerComponent
