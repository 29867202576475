import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
    {
        id: "Account",
        title: "Account",
        type: "item",
        icon: <Icon.User size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/"
    },
    {
        id: "ChangePassword",
        title: "ChangePassword",
        type: "item",
        icon: <Icon.Lock size={20}/>,
        permissions: ["admin", "editor"],
        navLink: "/ChangePassword"
    },
]

export default horizontalMenuConfig
