import React from "react"
import ScrollToTop from "react-scroll-up"
import {Button} from "reactstrap"
import {ArrowUp} from "react-feather"
import classnames from "classnames"
import {FormattedMessage} from "react-intl"

const Footer = props => {
    let footerTypeArr = ["sticky", "static", "hidden"]
    return (
        <footer
            className={classnames("footer footer-light", {
                "footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
                "d-none": props.footerType === "hidden"
            })}
        >
        </footer>
    )
}

export default Footer
