import React from "react";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,} from "reactstrap";
import axios from "axios";
import * as Icon from "react-feather";
import Lang from "./lang";
import keycloak from "./../../../keycloak";
import {FormattedMessage} from "react-intl";
import avatar1 from "../../../views/pages/images/avat.png";

const UserDropdown = (props) => {
    const roles = keycloak.tokenParsed.resource_access.website.roles
    const found = roles.includes("instructors");
    return (
        <DropdownMenu right>
            {found === true ?

                <DropdownItem
                    tag="a"
                    href={process.env.REACT_APP_INSTRUCTOR}
                >
                    <Icon.UserCheck size={14} className="mr-50"/>
                    <span className="align-middle">
              <FormattedMessage id="instructor dashboard"/>
            </span>
                </DropdownItem> : null}
            <DropdownItem tag="a" href={process.env.REACT_APP_PARTICIPANT}>
                <Icon.UserCheck size={14} className="mr-50"/>
                <span className="align-middle">
          {" "}
                    <FormattedMessage id="participant dashboard"/>
        </span>
            </DropdownItem>
            <DropdownItem tag="a" href={process.env.REACT_APP_PAYMENT}>
                <Icon.CreditCard size={14} className="mr-50"/>
                <span className="align-middle">
          {" "}
                    <FormattedMessage id="payment portal"/>
        </span>
            </DropdownItem>
            <DropdownItem
                tag="a"
                href={process.env.REACT_APP_COURSES}
                target="_blank"
            >
                <Icon.BookOpen size={14} className="mr-50"/>
                <span className="align-middle">
          <FormattedMessage id="Vol"/>
        </span>
            </DropdownItem>
            <DropdownItem
                tag="a"
                href={process.env.REACT_APP_VTS}
                target="_blank"
            >
                <Icon.Book size={14} className="mr-50"/>
                <span className="align-middle">
          <FormattedMessage id="Vts"/>
        </span>
            </DropdownItem>
            <DropdownItem tag="a" href={process.env.REACT_APP_VLC}>
                <Icon.Wifi size={14} className="mr-50"/>
                <span className="align-middle">
                    <FormattedMessage id="VLC"/>
                </span>
            </DropdownItem>

            <DropdownItem divider/>

            <DropdownItem
                tag="a"
                onClick={() => keycloak.logout()}
                className="btn-link align-middle"
            >
                <Icon.Power size={14} className="mr-50"/>
                <span className=" align-middle" onClick={() => keycloak.logout()}>
          {" "}
                    <FormattedMessage id="Logout"/>{" "}
        </span>
            </DropdownItem>
        </DropdownMenu>
    );
};

class NavbarUser extends React.PureComponent {
    state = {
        navbarSearch: false,
        langDropdown: false,
        suggestions: [],
    };

    componentDidMount() {
        axios.get("/api/main-search/data").then(({data}) => {
            this.setState({suggestions: data.searchResult});
        });
    }

    handleNavbarSearch = () => {
        this.setState({
            navbarSearch: !this.state.navbarSearch,
        });
    };

    handleLangDropdown = () => {
        this.setState({langDropdown: !this.state.langDropdown});
    };

    render() {
        return (
            <ul className="nav navbar-nav navbar-nav-user float-right">
                <Lang/>

                <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                    <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                        <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {keycloak.tokenParsed.name}
              </span>
                        </div>
                        <span data-tour="user">
              <img
                  src={
                      keycloak.tokenParsed.image
                          ? keycloak.tokenParsed.image
                          : avatar1
                  }
                  className="round"
                  height="40"
                  width="40"
                  alt="avatar"
              />
            </span>
                    </DropdownToggle>
                    <UserDropdown {...this.props} />
                </UncontrolledDropdown>
            </ul>
        );
    }
}

export default NavbarUser;
