import React from "react"
import {Navbar} from "reactstrap"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/no-avatar.png"
import HorizontalSidebar from "../menu/horizontal-menu/HorizontalMenu";

const ThemeNavbar = props => {
    const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"]
    const navbarTypes = ["floating", "static", "sticky", "hidden"]
    return (
        <>
            <HorizontalSidebar />
        </>
    )
}


export default ThemeNavbar
